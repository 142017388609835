<template>
  <div class="rating">
    <div
      v-for="star in 5"
      :key="star"
      @click="selectRating(star)"
      @mouseover="hoverRating(star)"
      @mouseleave="resetHover"
    >
      <svg
        width="30"
        height="28"
        viewBox="0 0 30 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :class="star <= (hoveredRating || rating) ? 'rated' : 'hi'"
      >
        <path
          d="M14.9998 1L19.1198 9.34667L28.3332 10.6933L21.6665 17.1867L23.2398 26.36L14.9998 22.0267L6.75984 26.36L8.33317 17.1867L1.6665 10.6933L10.8798 9.34667L14.9998 1Z"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RatingComponent',
  props: {
    rating: {
      type: Number,
      default: 0, // Provide a safe default value
    },
  },
  data() {
    return {
      hoveredRating: null,
    };
  },
  methods: {
    selectRating(star) {
      this.$emit('update:rating', star);
    },
    hoverRating(star) {
      this.hoveredRating = star;
    },
    resetHover() {
      this.hoveredRating = null;
    },
  },
};
</script>

<style scoped>
.rating {
  display: flex;
}

.rated {
  fill: #ed0000;
  stroke: #ed0000 !important;
}

.hi {
  fill: #676d71;
  stroke: #676d71;
}

.rating svg {
  stroke: #676d71;
}

.rating div:hover svg {
  fill: #ff8080;
  stroke: #ff8080;
}
</style>
