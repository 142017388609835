export const getApiBaseUrl = () => {
    const domain = window.location.hostname;
    switch (domain) {
        case 'automotive-roadassistancewebapp-staging.ispgnet.com':
            return 'https://ultimate-backend.common-preprod.ispgnet.com/api';
        case 'almulla-roadassistancewebapp-develop.ispgnet.com':
            return 'https://almulla-common-crm-backend.ispgnet.com/api';
        case 'localhost':
            return 'https://almulla-common-crm-backend.ispgnet.com/api';
        default:
            return 'https://ultimate-backend.common-preprod.ispgnet.com/api';
    }
};
